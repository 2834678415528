<template>
    <div class="add box_b">
        <!--  -->
        <div class="add_main box_b">
            <div class="vue_title">
                <span @click="back"><i class="el-icon-arrow-left"></i>返回</span>
                <p> {{id?'修改':'添加'}}</p>
            </div>
            <!--  -->
            <div class="add_info box_b" v-loading="loading">
                <div class="info_text">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>用户名</div>
                    <div class="t_right">
                        <el-input v-model="info.user_name" size='small' clearable maxlength="10" placeholder="请输入用户名"></el-input>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>头像</div>
                    <div class="t_right">
                        <el-upload class="r_img" accept="image/jpg,image/jpeg,image/png,image/gif" action="" :before-upload="beforeAvatarUpload" :http-request="httpRequestUserCover" :show-file-list="false">
                            <div v-if="info.user_cover" class="w100 h100">
                                <img class="w100 h100" style="border-radius: 50%;" :src="info.user_cover" alt="">
                            </div>
                            <div class="r_img_no w100 h100" v-else>
                                <i class="el-icon-plus"></i>
                                <div>上传头像</div>
                            </div>
                        </el-upload>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>职务</div>
                    <div class="t_right">
                        <el-input v-model="info.user_position" size='small' clearable maxlength="10" placeholder="请输入职务"></el-input>
                    </div>
                </div>

                <div class="info_text">
                    <div class="t_left f_s2 color6">点赞数</div>
                    <div class="t_right">
                        <el-input v-model="info.like_count" size='small' clearable maxlength="10" @input="limitNum($event,'like_count')" placeholder="请输入点赞数"></el-input>
                    </div>
                </div>

                <div class="info_text">
                    <div class="t_left f_s2 color6">转发数</div>
                    <div class="t_right">
                        <el-input v-model="info.transmit_count" size='small' clearable maxlength="10" @input="limitNum($event,'transmit_count')" placeholder="请输入转发数"></el-input>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left f_s2 color6">评论数</div>
                    <div class="t_right">
                        <el-input v-model="info.comment_count" size='small' clearable maxlength="10" @input="limitNum($event,'comment_count')" placeholder="请输入评论数"></el-input>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left f_s2 color6">图片</div>
                    <div class="t_right">
                        <div class="upload">
                            <div class="image" v-for="(item,index) in info.cover" :key="index">
                                <div class="mask"></div>
                                <i class="el-icon-close" @click="closeImg(index)"></i>
                                <img :src="item" alt="">
                            </div>
                            <el-upload v-if="info.cover.length<9" class="r_img bg11 b_r5 c_p" action="" :accept="imgAccept" multiple :show-file-list="false" :http-request="httpRequestMultiple" :before-upload="beforeAvatarUpload" :limit="9">
                                <div class="r_img_no w100 h100 t-align box_b">
                                    <i class="el-icon-plus f_s5 color7"></i>
                                    <div class="f_s1 color7">上传图片</div>
                                </div>
                            </el-upload>
                        </div>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>内容</div>
                    <!-- <div class="edit">
                        <div style="border: 1px solid #DCDFE6;">
                            <Toolbar style="width: 100%;border-bottom: 1px solid #DCDFE6" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
                            <Editor style="height: 350px; width: 100%; overflow-y: hidden;" v-model="info.title" :defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" />
                        </div>
                    </div> -->
                    <div class="t_right">
                        <el-input v-model="info.title" type="textarea" :rows="4" placeholder="请输入内容"></el-input>
                    </div>
                </div>

            </div>
            <!--  -->
            <div class="dialog-footer">
                <el-button type="primary" size='small' @click="confirm" :loading="btnLoading">确 定</el-button>
                <el-button size='small' @click="chongzhi">重 置</el-button>
            </div>

        </div>
    </div>
</template>
  
  <script>
import '@wangeditor/editor/dist/css/style.css'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
    name: "notice_add",
    components: {
        Editor,
        Toolbar
    },
    props: {

    },
    data() {
        return {
            headers: {
                'Authorization': this.$store.state.token
            },
            loading: false,
            btnLoading: false,
            imgAccept: 'image/jpg,image/jpeg,image/png,image/gif',
            imgTip: '图片：大小≤3MB，建议“正方形”图片（即1:1的宽高），上限9张，图片最大宽高为750*750，只能上传jpg、jpeg、png格式文件。',
            info: {
                title: "",
                user_name: "",
                cover: [],
                user_cover: "",
                like_count: "",
                transmit_count: "",
                comment_count: "",
                user_position: "",
            },
            id: "",
            editor: null,
            toolbarConfig: {},
            editorConfig: {
                placeholder: '请输入内容...',
                // 所有的菜单配置，都要在 MENU_CONF 属性下
                MENU_CONF: {
                    // 配置上传图片
                    uploadImage: {
                        server: '/admin/utils/upload_file',
                        customUpload: this.update
                    },
                    // 配置上传视频
                    uploadVideo: {
                        server: '/admin/utils/upload_file',
                        customUpload: this.update
                    },
                    // 继续其他菜单配置...
                }
            },
            mode: 'default', // or 'simple'
        };
    },

    created() {
        if (this.$route.query.id) {
            this.id = this.$route.query.id
            this.getInfo()
        }
    },

    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    },
    methods: {
        // 富文本上传图片
        update(file, insertFn) {
            var that = this;
            let form = new FormData();
            form.append("file", file);
            that.$publicApi.uploadFile(form).then(res => {
                if (res.code == 1000) {
                    insertFn(res.result.url, file.name, res.result.url)
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        confirm() {
            if (this.info.user_name == '') {
                this.$errMsg('请输入用户名')
                return
            }
            if (this.info.user_cover == '') {
                this.$errMsg('请上传头像')
                return
            }
            if (this.info.user_position == '') {
                this.$errMsg('请输入职务')
                return
            }
            if (this.$testVerify.isContent(this.info.title)) {
                this.$errMsg('请输入内容')
                return
            }
            // 修改
            if (this.id) {
                this.edit()
            } else {
                // 添加
                this.add()
            }
        },
        // 添加
        add() {
            var img;
            if (this.info.cover.length > 0) {
                img = this.info.cover.toString()
            } else {
                img = ""
            }
            this.btnLoading = true;
            var params = {
                title: this.info.title,
                user_name: this.info.user_name,
                cover: img,
                user_cover: this.info.user_cover,
                like_count: this.info.like_count,
                transmit_count: this.info.transmit_count,
                comment_count: this.info.comment_count,
                user_position: this.info.user_position,
            }
            this.$flowApi.flowAdd(params).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.btnLoading = false;
                    this.$router.push('/flow/list')
                } else {
                    this.$errMsg(res.message)
                    this.btnLoading = false;
                }
            })
        },
        edit() {
            var img;
            if (this.info.cover.length > 0) {
                img = this.info.cover.toString()
            } else {
                img = ""
            }
            this.btnLoading = true;
            var params = {
                title: this.info.title,
                user_name: this.info.user_name,
                cover: this.info.cover.toString(),
                user_cover: this.info.user_cover,
                like_count: this.info.like_count,
                transmit_count: this.info.transmit_count,
                comment_count: this.info.comment_count,
                user_position: this.info.user_position,
            }
            this.$flowApi.flowEdit(params, this.id).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.btnLoading = false;
                    this.$router.push('/flow/list')
                } else {
                    this.$errMsg(res.message)
                    this.btnLoading = false;
                }
            })
        },

        getInfo() {
            this.loading = true;
            this.$flowApi.flowInfo(this.id).then(res => {
                if (res.code == 1000) {
                    this.info = {
                        title: res.result.title,
                        user_cover: res.result.user_cover,
                        cover: res.result.cover,
                        user_name: res.result.user_name,
                        like_count: res.result.like_count,
                        transmit_count: res.result.transmit_count,
                        comment_count: res.result.comment_count,
                        user_position: res.result.user_position,
                    }
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.$errMsg(res.message)
                }
            })
        },
        chongzhi() {
            this.info = {
                title: "",
                user_name: "",
                cover: [],
                user_cover: "",
                like_count: "",
                transmit_count: "",
                comment_count: "",
                user_position: "",
            }
        },
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },



        closeImg(index) {
            this.info.cover.splice(index, 1)
        },
        // 上传图片
        httpRequestMultiple(uploader) {
            const formData = new FormData();
            formData.set('file', uploader.file);
            this.$publicApi.uploadFile(formData).then(res => {
                if (res.code === 1000) {
                    if (this.info.cover.length < 9) {
                        this.info.cover.push(res.result.url)
                        uploader.onSuccess();
                    } else {
                        // this.$message.error('最多上传9张图片');
                        return
                    }
                } else {
                    this.$message.error(res.message);
                }
            })
        },
        // 上传图片限制
        beforeAvatarUpload(file, index) {
            this.index = index
            const isLt10KB = file.size / 1024 < 3 * 1024;
            let types = this.imgAccept.split(',');
            const isType = types.includes(file.type);
            if (!isType) {
                this.$message.error('只能上传只' + this.imgAccept + '格式!');
            }
            if (!isLt10KB) {
                this.$message.error('上传图片大小不能超过 3M!');
            }

            return isType && isLt10KB;
        },

        // 上传头像
        httpRequestUserCover(uploader) {
            const formData = new FormData();
            formData.set('file', uploader.file);
            this.$publicApi.uploadFile(formData).then(res => {
                if (res.code === 1000) {
                    this.info.user_cover = res.result.url
                } else {
                    this.$message.error(res.message);
                }
            })
        },
        // 输入框限制
        limitNum(e, type) {
            if (type == 'like_count') {
                this.info.like_count = e.replace(/[^0-9.]/g, '')
            }
            if (type == 'transmit_count') {
                this.info.transmit_count = e.replace(/[^0-9.]/g, '')
            }
            if (type == 'comment_count') {
                this.info.comment_count = e.replace(/[^0-9.]/g, '')
            }
        },
        back() {
            this.$router.back()
        },
    },
}
  </script>
  <style lang="scss" scoped>
// @import "../../assets/style/common.less";

.add {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .add_main {
        width: 100%;
        height: 100%;
    }

    .sms_title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding-bottom: 10px;
        border-bottom: 1px solid #f4f5fa;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .add_info {
        width: 100%;
        height: calc(100% - 90px);
        padding: 15px 0;
        overflow-y: auto;

        .info_text {
            width: 100%;
            padding: 12px 0 0;
            overflow: hidden;
            display: flex;
            .switch {
                width: 90px;
                text-align: right;
                margin-right: 14px;
            }
            .edit {
                width: 1000px;
                margin-left: 10px;
                padding-top: 6px;
                position: relative;
                z-index: 1000;
            }
            .t_left {
                float: left;
                width: 110px;
                line-height: 32px;
                text-align: right;
            }
            .t_right {
                float: left;
                width: 500px;
                margin-left: 12px;
                display: flex;
                align-items: flex-end;
                flex-wrap: wrap;
                .r_img {
                    width: 80px;
                    height: 80px;
                    cursor: pointer;
                    border: 1px solid #e8eaec;
                    background-color: #f5f7fa;
                    border-radius: 5px;
                    .r_img_no {
                        padding-top: 20px;
                        font-size: 14px;
                        color: #94979f;
                        i {
                            font-size: 18px;
                        }
                        div {
                            padding-top: 5px;
                        }
                    }
                }
                .r_txt {
                    padding-left: 10px;
                }
                .el-select {
                    width: 100%;
                }
            }
            .t_right_select {
                float: left;
                width: 500px;
                margin-left: 12px;
                .el-select {
                    width: 100%;
                }
            }
            .radio {
                padding-left: 10px;
                display: flex;
                align-items: center;
                height: 32px;
            }
        }
    }
}
.upload {
    width: 680px;
    display: flex;
    flex-wrap: wrap;

    .r_img {
        width: 80px;
        height: 80px;
        margin-right: 8px;
        margin-bottom: 8px;
        background-color: #f5f7fa;
        border-radius: 4px;

        .r_img_no {
            padding-top: 20px;
            i {
                font-size: 20px;
            }

            div {
                padding-top: 5px;
            }
        }
    }

    .image {
        width: 80px;
        height: 80px;
        margin-right: 8px;
        margin-bottom: 8px;
        position: relative;
        cursor: pointer;

        img {
            width: 80px;
            height: 80px;
            border-radius: 4px;
        }

        .mask {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            background: rgba(0, 0, 0, 0.3);
            display: none;
        }

        .el-icon-close {
            position: absolute;
            right: 3px;
            top: 3px;
            display: none;
            color: #ffffff;
            font-size: 20px;
            z-index: 100;
        }
    }

    .image:hover {
        .el-icon-close {
            display: block;
        }

        .mask {
            display: block;
        }
    }

    .r_txt {
        padding-left: 10px;
    }

    .el-select {
        width: 100%;
    }
}
.dialog-footer {
    padding-left: 50px;
    padding-bottom: 20px;
}
.box_b {
    box-sizing: border-box;
}
.f_s2 {
    font-size: 14px;
    line-height: 20px;
}
.w100 {
    width: 100%;
}
.h100 {
    height: 100%;
}
.color1 {
    color: #e51d41;
}

.color6 {
    color: #606266;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}
::v-deep .el-upload {
    width: 100%;
    height: 100%;
}
</style>
  